import React from 'react'

const useScrollPositionCallback = (offset = 0) => {
  const [scrollY, setScrollY] = React.useState(0)
  const refCallback = React.useCallback(element => {
    if (element) {
      const { top } = element.getBoundingClientRect()
      const distance = top + window.scrollY
      setScrollY(distance - offset)
    }
  })
  return {
    refCallback,
    scrollY,
  }
}

export default useScrollPositionCallback
