import React from 'react'
import { Router } from '@reach/router'
import InsightController from '../../components/Insights/InsightController'
import Media from '../../components/Insights/Media'

const Insights = () => {
  // do not render the html for client side routes at build time
  if (typeof window === 'undefined') {
    return null
  }
  return (
    <Router>
      <Media path="/insights" />
      <InsightController path="/insights/announcements/:slug" />
      <InsightController path="/insights/news/:slug" />
      <InsightController path="/insights/features/:slug" />
    </Router>
  )
}

export default Insights;