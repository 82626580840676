import React from 'react'
import { Input, Tag } from 'antd'
import styled from 'styled-components'
import Button from '../Button/Button'
import { ANNOUNCEMENT, FEATURE, NEWS } from '../../constants/constants'
import useInsightsFilters from '../../hooks/useInsightsFilters'
import InsightsContext from '../../context/insightsContext'

const Search = styled(Input.Search)`
  max-width: 400px;

  .ant-input-affix-wrapper {
    height: 36px;
  }

  .ant-input-group-addon:last-child {
    background-color: transparent;
  }

  .ant-input-search-button {
    bottom: 1px;
  }
`

const Container = styled.div`
  display: flex;
  max-width: 1226px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  @media (max-width: 1200px) {
    max-width: 820px;
    align-items: center;
  }
`

const Wrapper = styled(Container)`
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-right: 10px;

  > button:not(:first-child) {
    margin-left: 20px;
  }

  @media (max-width: 1200px) {
    > button {
      margin-top: 20px;
    }
  }

  @media (max-width: 500px) {
    > button:nth-child(3) {
      margin-left: 0;
    }
  }
`

const TagFilterWrapper = styled(Container)`
  margin-top: 20px;
  justify-content: flex-end;
  align-items: flex-end;
  @media (max-width: 1200px) {
    justify-content: center;
  }
`

const TagFilter = styled(Tag)`
  color: #122453;
  font-family: 'FFDINWebPro';
  font-size: 14px;
  font-weight: 600;
  padding: 5px 6px 5px 12px;
`

const Filters = () => {
  const [search, setSearch] = React.useState('')
  const { scrollY } = React.useContext(InsightsContext)

  const {
    params: { tag, type, search: searchFilter },
    setTag,
    setType,
    setSearch: setSearchFilter,
  } = useInsightsFilters(scrollY)
  React.useEffect(() => {
    setSearch(searchFilter)
  }, [searchFilter])

  const handleChange = React.useCallback(e => {
    setSearch(e.target.value)
  })

  return (
    <div>
      <Wrapper>
        <Search value={search} onChange={handleChange} onSearch={setSearchFilter} placeholder="Search..." allowClear />
        <ButtonWrapper>
          <Button
            id="InsightsFilters-feature"
            active={type.includes(FEATURE)}
            onClick={() => setType(FEATURE)}
            icon={FEATURE}
            type={FEATURE}
          >
            Features
          </Button>
          <Button id="InsightsFilters-news" active={type.includes(NEWS)} onClick={() => setType(NEWS)} icon={NEWS} type={NEWS}>
            News
          </Button>
          <Button
            id="InsightsFilters-announcement"
            active={type.includes(ANNOUNCEMENT)}
            onClick={() => setType(ANNOUNCEMENT)}
            icon={ANNOUNCEMENT}
            type={ANNOUNCEMENT}
          >
            Announcements
          </Button>
        </ButtonWrapper>
      </Wrapper>
      {!!tag && (
        <TagFilterWrapper>
          <TagFilter closable onClose={() => setTag('')}>
            #{tag}
          </TagFilter>
        </TagFilterWrapper>
      )}
    </div>
  )
}

export default Filters
