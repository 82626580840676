import React from 'react'
import { Helmet } from 'react-helmet'
import { get } from 'lodash'
import { Pagination } from 'antd'
import styled from 'styled-components'
import auth from '../../helpers/auth'
import Layout from '../../layouts/Layout'
import './media.scss'
import Loader from '../../components/Login/Loader/Loader'
import useGetInsights from '../../apollo/query/insights/useGetInsights'
import { INSIGHT_STATUS_PUBLISHED } from '../../constants/constants'
import ErrorHandler from '../../components/Error/ErrorHandler'
import List from '../../components/Media/List/List'
import Row from '../../components/Row/Row'
import LoadingOverlay from '../../components/Admin/ui/LoadingOverlay'
import useInsightsFilters from 'src/hooks/useInsightsFilters'
import { header, headerRow, media, mediaPage } from './media.module.scss'
import Filters from './Filters'
import Header from './Header'
import useScrollPositionCallback from '../../hooks/useScrollPostionCallback'
import InsightsContext from '../../context/insightsContext'

const StyledPagination = styled(Pagination)`
  li {
    min-width: 42px;
    height: 42px;
    margin-right: 12px;
    font-size: 20px;
    line-height: 40px;
  }
  li > a {
    color: #10255c;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`
const PAGE_SIZE = 12

const Media = () => {
  const { refCallback, scrollY } = useScrollPositionCallback()
  const {
    params: { page, type, search, tag },
    setPage,
  } = useInsightsFilters(scrollY)

  const isAdmin = auth().isAdmin()

  const { data, loading, error } = useGetInsights({
    params: {
      status: INSIGHT_STATUS_PUBLISHED,
      version: isAdmin,
      type: type.join(','),
      page,
      limit: PAGE_SIZE,
      search,
      tags: tag ? [tag] : undefined,
    },
    withTags: true,
    withAuthors: true,
  })

  const insights = get(data, 'insights.results') || []
  const { total } = get(data, 'insights') || {}

  return (
    <InsightsContext.Provider value={{ scrollY }}>
      <Layout className={mediaPage} hasDownArrow>
        <Helmet>
          <title>Insights - Emso</title>
        </Helmet>
        {loading && !insights.length && <Loader fullScreen />}
        <Header />
        <div ref={refCallback} />
        <ErrorHandler error={error}>
          <div className={media} id="media">
            <LoadingOverlay transparent={false} loading={loading}>
              <Row className={headerRow}>
                <h2 className={header}>Our latest thoughts</h2>
              </Row>
              <Filters />
              <Row>
                <List medias={insights} />
              </Row>
              <PaginationWrapper>
                <StyledPagination current={page} pageSize={PAGE_SIZE} onChange={setPage} total={total} showSizeChanger={false} />
              </PaginationWrapper>
            </LoadingOverlay>
          </div>
        </ErrorHandler>
      </Layout>
    </InsightsContext.Provider>
  )
}

export default Media
