import * as React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card/Card'
import { row, card, spacerThree, spacerTwo } from './List.module.scss'

const List = React.memo(({ medias }) => (
  <div className={row}>
    {!medias.length && <span>No Results</span>}
    {medias &&
      medias.map(media => {
        return <Card {...media} key={media.uuid} className={card} />
      })}
    {medias.length % 3 !== 0 && <div className={spacerThree} />}
    {medias.length % 2 !== 0 && <div className={spacerTwo} />}
  </div>
))

List.propTypes = {
  medias: PropTypes.array.isRequired,
}

export default List
