import React from 'react'
import { get } from 'lodash'
import Hero from '../Hero/Hero'
import heroImage from '../../data/images/cityskyline.jpg'
import { heroData } from '../../data/media'
import auth from '../../helpers/auth'
import { INSIGHT_STATUS_PUBLISHED } from '../../constants/constants'
import Col from '../Col/Col'
import NextSection from '../NextSection/NextSection'
import useGetInsights from '../../apollo/query/insights/useGetInsights'
import { hero } from './media.module.scss'
import Slider from './Slider'

const Header = () => {
  const isAdmin = auth().isAdmin()
  const { data } = useGetInsights({
    params: { limit: 3, requireImage: true, status: INSIGHT_STATUS_PUBLISHED, version: isAdmin },
    withTags: true,
    withAuthors: true,
  })

  const insights = get(data, 'insights.results') || []

  return (
    <>
      <Hero
        image={heroImage}
        {...heroData}
        className={hero}
        backgroundOpacity={0.25}
        childrenInner={
          <Col small={12}>
            <div className="media-module">
              <Slider insights={insights}/>
            </div>
          </Col>
        }
      />
      <NextSection/>
    </>
  )
}

export default Header
