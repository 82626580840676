import React from 'react'
import SlickSlider from 'react-slick'
import PropTypes from 'prop-types'
import Card from '../Media/Card/Card'
import { card } from './media.module.scss'

const Slider = ({ insights }) => {
  if (!insights.length) {
    return null
  }
  const settings = {
    arrows: false,
    dots: true,
    speed: 700,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
  }
  return (
    <SlickSlider {...settings}>
      {insights.map(insight => {
        return <Card key={insight.uuid} wide className={card} {...insight} author={{ name: insight.authorName }}/>
      })}
    </SlickSlider>
  )
}

Slider.propTypes = {
  insights: PropTypes.array,
}

Slider.defaultProps = {
  insights: [],
}

export default Slider
