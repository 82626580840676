import React from 'react'
import { get } from 'lodash'
import { navigate } from 'gatsby'
import InsightQuery from '../../apollo/query/insights/Insight'
import InsightView from './InsightView'
import Loader from '../Login/Loader/Loader'
import auth from '../../helpers/auth'
import { INSIGHT_STATUS_PUBLISHED, INSIGHT_STATUSES } from '../../constants/constants'
import Layout from '../../layouts/Layout'
import { mediaPage } from './media.module.scss'

const InsightController = ({ uri }) => {
  const isAdmin = auth().isAdmin()
  const status = isAdmin ? INSIGHT_STATUSES.join(',') : INSIGHT_STATUS_PUBLISHED
  return (
    <Layout className={mediaPage} hasDownArrow>
      <InsightQuery path={uri} status={status} version={isAdmin} withTags>
        {({ data, loading }) => {
          const insight = get(data, 'insight')
          if (insight === null) {
            navigate('/insights')
            return null
          }
          if (loading) return <Loader fullScreen />
          return (
            <InsightView insight={insight || {}}/>
          )
        }}
      </InsightQuery>
    </Layout>
  )
}

export default InsightController
